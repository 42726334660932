/** @jsx jsx */

import { Link } from 'gatsby'
import { jsx, Box, Flex, Heading } from 'theme-ui'

const NotFoundPage = () => {
  return (
    <Flex
      sx={{
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Heading as='h1'
        sx={{
          fontSize: [6, 6, 7],
        }}
      >
        not found
      </Heading>
      <Heading as='h2'>
        that page doesn’t exist
      </Heading>
      <Box
        sx={{
          background: 'primary',
          textAlign: 'center',
          mt: 3,
          fontSize: 4,
        }}
      >
        <Link to='/'
          sx={{
            color: 'text',
            textDecoration: 'none',
            px: 4,
            py: 3,
            display: 'inline-block',
          }}
        >
          go back
        </Link>
      </Box>
    </Flex>
  )
}

export default NotFoundPage
